import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom"; // import Dashboard from "../components/dashboard/Dashboard";
const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const Event = lazy(() => import("../components/Event/Event"));
const EditEvent = lazy(() => import("../components/EditEvent/EditEvent"));
const EmployeeList = lazy(() =>
  import("../components/EmployeeList/EmployeeList")
);
const CompOffLeaveList = lazy(() =>
  import("../components/leave/addleave/CompOffLeaveList")
);
const AdminLeaveApprove = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprove")
);
const LeaveMaster = lazy(() =>
  import("../components/leave/addleave/LeaveMaster")
);
const EmployeeMasterList = lazy(() =>
  import("../components/EmployeeMasterList/EmployeeMasterList")
);
const CreditScoreReport = lazy (()=>
  import("../components/CreditScoreReport/CreditScoreReport")
);
const WeeklyEventReport = lazy(()=>
import("../components/WeeklyEventReport/WeeklyEventReport"))

const AnnualEventReport = lazy(()=>
import("../components/AnnualEventReport/AnnualEventReport"))
const EmployeeInout = lazy(() =>
  import("../components/EmployeeInout/EmployeeInout")
);
const AdminRegularization = lazy(() =>
  import("../components/AdminRegurlarization/AdminRegurlarization")
);
const CompOffApprove = lazy(() =>
  import("../components/leave/addleave/CompOffApprove")
);
const LeaveCancellation = lazy(() =>
  import("../components/leave/addleave/LeaveCancellation")
);
const Exemployee = lazy(() =>
  import("../components/ExemployeeList/ExemployeeList")
);
const AddResignation = lazy(() =>
  import("../components/AddResignation/AddResignation")
);
const EditResignation = lazy(() =>
  import("../components/EditResignation/EditResignation.")
);
const DepartmentList = lazy(() =>
  import("../components/DepartmentList/DepartmentList")
);
const AddDepartment = lazy(() =>
  import("../components/AddDepartment/AddDepartment")
);
const NotificationEvent = lazy(() =>
  import("../components/Notifaction/NotificationEvent")
);
const AddNotification = lazy(() =>
  import("../components/AddNotification/AddNotifiction")
);
const AdminList = lazy(() => import("../components/AdminList/AdminList"));
const AddAdmin = lazy(() => import("../components/AddAdmin/AddAdmin"));
const AddEvent = lazy(() => import("../components/AddEvent/AddEvent"));
const EventTypeList = lazy(() =>
  import("../components/EventTypeList/EventTypeList")
);
const AddEventType = lazy(() =>
  import("../components/AddEventType/AddEventType")
);
const AddEmployee = lazy(() => import("../components/AddEmployee/AddEmployee"));
const EditEmployee = lazy(() =>
  import("../components/EditEmployee/EditEmployee")
);
const ReportListing = lazy(()=>
import("../components/ReportListing/ReportListing"));
const MyInOut = lazy(() => import("../components/MyInOut/MyInOut"));
const AttendenceRegularization = lazy(() =>
  import("../components/AttendenceRegularization/AttendenceRegularization")
);
const ManageTeamRegularization = lazy(() =>
  import("../components/ManageTeamRegularization/ManageTeamRegularization")
);
const AddEmployeeMaster = lazy(() =>
  import("../components/AddEmployeeMaster/AddEmployeeMaster")
);
const EditEmployeeMaster = lazy(() =>
  import("../components/EditEmployeeMaster/EditEmployeeMaster")
);
const EditEventType = lazy(() =>
  import("../components/EditEventType/EditEventType")
);
const EditDepartment = lazy(() =>
  import("../components/EditDepartment/EditDepartment")
);
const AdminEdit = lazy(() => import("../components/AdminEdit/AdminEdit"));
const PreviousEvent = lazy(() =>
  import("../components/PreviousEvent/PreviousEvent")
);
const LiveEvent = lazy(() => import("../components/LiveEvent/LiveEvent"));
const EventView = lazy(() => import("../components/EventView/EventView"));
const UpComingEvent = lazy(() =>
  import("../components/UpComingEvent/UpComingEvent")
);
const AddTrainer = lazy(() => import("../components/AddTrainer/AddTrainer"));
// const EventTypeView = lazy(() => import('../components/EventTypeView/EventTypeView'));
const FeedbackView = lazy(() =>
  import("../components/FeedbackView/FeedbackView")
);
const EmployeeView = lazy(() =>
  import("../components/EmployeeView/EmployeeView")
);
const TrainerName = lazy(() => import("../components/TrainerName/TrainerName"));
const TrainerView = lazy(() => import("../components/TrainerView/TrainerView"));
const UserAttendance = lazy(() =>
  import("../components/UserAttendance/userAttendance")
);
// const FeedbackView = lazy(() =>
//   import("../components/FeedbackView/FeedbackView")
// );
// const EmployeeView = lazy(() =>
//   import("../components/EmployeeView/EmployeeView")
// );
const EmployeeMasterView = lazy(() =>
  import("../components/EmployeeMasterView/EmployeeMasterView")
);
const ResignationView = lazy(() =>
  import("../components/ResignationView/ResignationView")
);
// const TrainerName = lazy(() => import("../components/TrainerName/TrainerName"));
// const TrainerView = lazy(() => import("../components/TrainerView/TrainerView"));
// const UserAttendance = lazy(() => import('../components/UserAttendance/userAttendance'));

const SurveyAdd = lazy(() => import("../components/SurveyAdd/SurveyAdd"));
const SurveyEdit = lazy(() => import("../components/SurveyEdit/SurveyEdit"));
const SurveyList = lazy(() => import("../components/SurveyList/SurveyList"));
const SurveyUsersList = lazy(() =>
  import("../components/SurveyUsersList/SurveyUsersList")
);
const SurveyAnsView = lazy(() =>
  import("../components/SurveyAnsView/SurveyAnsView")
);

const ElevateFormList = lazy(() =>
  import("../components/ElevateFormList/ElevateFormList")
);
const ElevateFormAdd = lazy(() =>
  import("../components/ElevateFormAdd/ElevateFormAdd")
);
const ElevateFormEdit = lazy(() =>
  import("../components/ElevateFormEdit/ElevateFormEdit")
);

const Birthday_model = lazy(() =>
  import("../components/Birthday_module/Birthday_model")
);
const AddPostCard = lazy(() => import("../components/AddPostCard/AddPostCard"));
const AddTemplate = lazy(() => import("../components/AddTemplate/AddTemplate"));

//manual attedance
const ManualAttedance = lazy(() =>
  import("../components/ManualAttedance/ManualAttedance")
);
//leave module
const LeaveList = lazy(() => import("../components/leave/addleave/LeaveList"));
const AddLeave = lazy(() => import("../components/leave/addleave/AddLeave"));
const CompOffLeave = lazy(() =>
  import("../components/leave/addleave/CompOffLeave")
);

const local = localStorage.getItem("user_data");
const HRMSValidate = JSON.parse(local);
const App = ({ match }) => (
  <>



    {!HRMSValidate.emp_code ? <>
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route exact path={`${match.url}dashboard`} component={Dashboard} />
          <Route exact path={`${match.url}report`} component={ReportListing} />
          <Route exact path={`${match.url}creditscore`} component={CreditScoreReport} />
          <Route exact path={`${match.url}weeklyeventreport`} component={WeeklyEventReport}/>
          <Route exact path = {`${match.url}annualEventReport`} component={AnnualEventReport}/>

          <Route exact path={`${match.url}event`} component={Event} />
          <Route
            exact
            path={`${match.url}event/add-event`}
            component={AddEvent}
          />
          <Route
            exact
            path={`${match.url}event/update-event/:id`}
            component={EditEvent}
          />
          <Route
            exact
            path={`${match.url}event/event-view/:id`}
            component={EventView}
          />
          <Route
            exact
            path={`${match.url}previous-event`}
            component={PreviousEvent}
          />
          <Route
            exact
            path={`${match.url}live-event`}
            component={LiveEvent}
          />

          <Route
            exact
            path={`${match.url}upcoming-event`}
            component={UpComingEvent}
          />
          <Route
            exact
            path={`${match.url}user-attendance`}
            component={UserAttendance}
          />
          <Route
            exact
            path={`${match.url}event-type`}
            component={EventTypeList}
          />
          <Route
            exact
            path={`${match.url}event-type/add-event-type`}
            component={AddEventType}
          />
          <Route
            exact
            path={`${match.url}event-type/update-event-type/:id`}
            component={EditEventType}
          />
          {/* <Route exact path={`${match.url}event-type/event-type-view/:id`} component={EventTypeView} /> */}

          <Route
            exact
            path={`${match.url}department`}
            component={DepartmentList}
          />
          <Route
            exact
            path={`${match.url}department/add-department`}
            component={AddDepartment}
          />
          <Route
            exact
            path={`${match.url}department/update-department/:id`}
            component={EditDepartment}
          /> 
          <Route
            exact
            path={`${match.url}feedback-view/:id`}
            component={FeedbackView}
          />
          <Route
            exact
            path={`${match.url}employee`}
            component={EmployeeList}
          />

          <Route
            exact
            path={`${match.url}employee/add-employee`}
            component={AddEmployee}
          />
          <Route
            exact
            path={`${match.url}employee/update-employee/:id`}
            component={EditEmployee}
          />
          <Route
            exact
            path={`${match.url}employee/view-employee/:id`}
            component={EmployeeView}
          />

          {/* <Route
          exact
          path={`${match.url}employee-master`}
          component={EmployeeMasterList}
        />
        <Route
          exact
          path={`${match.url}employee-master/add-employee-master`}
          component={AddEmployeeMaster}
        />
        <Route
          exact
          path={`${match.url}employee-master/update-employee-master/:id`}
          component={EditEmployeeMaster}
        />
        <Route
          exact
          path={`${match.url}employee-master/view-employee-master/:id`}
          component={EmployeeMasterView}
        />

        <Route
          exact
          path={`${match.url}ex-employee`}
          component={Exemployee}
        />
        <Route
          exact
          path={`${match.url}ex-employee/add-resignation`}
          component={AddResignation}
        />
        <Route
          exact
          path={`${match.url}ex-employee/update-resignation/:id`}
          component={EditResignation}
        />
        <Route
          exact
          path={`${match.url}ex-employee/view-resignation/:id`}
          component={ResignationView}
        /> */}

          <Route exact path={`${match.url}host`} component={TrainerName} />
          <Route
            exact
            path={`${match.url}add-trainer`}
            component={AddTrainer}
          />
          <Route
            exact
            path={`${match.url}host/view-host/:id`}
            component={TrainerView}
          />

          <Route
            exact
            path={`${match.url}elevate-forms`}
            component={ElevateFormList}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/add`}
            component={ElevateFormAdd}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/edit/:id`}
            component={ElevateFormEdit}
          />

          <Route
            exact
            path={`${match.url}elevate-forms/surveys-add`}
            component={SurveyAdd}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/surveys-edit/:id`}
            component={SurveyEdit}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/surveys`}
            component={SurveyList}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/surveys/users/:id`}
            component={SurveyUsersList}
          />
          <Route
            exact
            path={`${match.url}elevate-forms/surveys/users/answers/:id`}
            component={SurveyAnsView}
          />

          <Route
            exact
            path={`${match.url}admin-edit`}
            component={AdminEdit}
          />
          <Route exact path={`${match.url}add-admin`} component={AddAdmin} />
          <Route
            exact
            path={`${match.url}notification`}
            component={NotificationEvent}
          />
          <Route
            exact
            path={`${match.url}notification/add-notification`}
            component={AddNotification}
          />
          <Route
            exact
            path={`${match.url}birthday`}
            component={Birthday_model}
          />
          <Route
            exact
            path={`${match.url}addPostcard`}
            component={AddPostCard}
          />
          <Route
            exact
            path={`${match.url}templates`}
            component={AddTemplate}
          />
          <Route
            exact
            path={`${match.url}leave-application`}
            component={LeaveList}
          />
          <Route
            exact
            path={`${match.url}leave-application/add-leave`}
            component={AddLeave}
          />
          <Route
            exact
            path={`${match.url}leave-application/compoff-leave`}
            component={CompOffLeaveList}
          />
          <Route
            exact
            path={`${match.url}leave-application/add-compoff-leave`}
            component={CompOffLeave}
          />
          <Route
            exact
            path={`${match.url}leave-master`}
            component={LeaveMaster}
          />
          <Route
            exact
            path={`${match.url}admin-approve`}
            component={AdminLeaveApprove}
          />
          <Route
            exact
            path={`${match.url}compoff-approve`}
            component={CompOffApprove}
          />
          <Route
            exact
            path={`${match.url}leave-cancellation`}
            component={LeaveCancellation}
          />

          <Route
            exact
            path={`${match.url}admin-edit`}
            component={AdminEdit}
          />
          <Route exact path={`${match.url}add-admin`} component={AddAdmin} />
          <Route
            exact
            path={`${match.url}notification`}
            component={NotificationEvent}
          />
          <Route
            exact
            path={`${match.url}notification/add-notification`}
            component={AddNotification}
          />
          <Route
            exact
            path={`${match.url}birthday`}
            component={Birthday_model}
          />
          <Route
            exact
            path={`${match.url}addPostcard`}
            component={AddPostCard}
          />
          <Route
            exact
            path={`${match.url}templates`}
            component={AddTemplate}
          />

          <Route exact path={`${match.url}setting`} component={AdminList} />

          {/* manual attendance */}
          <Route
            exact
            path={`${match.url}event/manual-attedance/:id`}
            component={ManualAttedance}
          />

          <Redirect from="/" to="/dashboard" />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </div>
    </>

      :
      (HRMSValidate?.role_id?._id === "60e68a055f40862222c64ec9" || HRMSValidate?.role_id === "60e68a055f40862222c64ec9") ? <>
        <div className="gx-main-content-wrapper">
          <Switch>
            <Route
              exact
              path={`${match.url}employee-master`}
              component={EmployeeMasterList}
            />
            <Route
              exact
              path={`${match.url}employee-master/add-employee-master`}
              component={AddEmployeeMaster}
            />
            <Route
              exact
              path={`${match.url}employee-master/update-employee-master/:id`}
              component={EditEmployeeMaster}
            />
            <Route
              exact
              path={`${match.url}employee-master/view-employee-master/:id`}
              component={EmployeeMasterView}
            />

            <Route
              exact
              path={`${match.url}ex-employee`}
              component={Exemployee}
            />
            <Route
              exact
              path={`${match.url}ex-employee/add-resignation`}
              component={AddResignation}
            />
            <Route
              exact
              path={`${match.url}ex-employee/update-resignation/:id`}
              component={EditResignation}
            />
            <Route
              exact
              path={`${match.url}ex-employee/view-resignation/:id`}
              component={ResignationView}
            />
            <Route
              exact
              path={`${match.url}employee-in-out`}
              component={EmployeeInout}
            />
            <Route
              exact
              path={`${match.url}admin-regularizatrion`}
              component={AdminRegularization}
            />
            <Redirect from="/" to="/employee-master" />
            <Redirect from="*" to="/employee-master" />
          </Switch>
        </div>
      </> : (HRMSValidate?.role_id?._id === "60e68a875f40862222c64ecb" || HRMSValidate?.role_id === "60e68a875f40862222c64ecb") ? <>
        <div className="gx-main-content-wrapper">
          <Switch>
            <Route exact path={`${match.url}my-in-out`} component={MyInOut} />
            <Route
              exact
              path={`${match.url}employee-attendence-regularization`}
              component={AttendenceRegularization}
            />
            <Route
              exact
              path={`${match.url}manage-team-regularization`}
              component={ManageTeamRegularization}
            />
            <Redirect from="/" to="/my-in-out" />
            <Redirect from="*" to="/my-in-out" />
          </Switch>
        </div>
      </> : <></>

    }
  </>
);

export default App;
